/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetIframe" }>;
}

const IframeWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <iframe
        src={widget.iframeShape?.src}
        height={widget.iframeShape?.height}
        frameBorder={0}
        sx={{
          border: 0,
          width: "100%",
          backgroundColor: "transparent",
          display: "block",
        }}
        referrerPolicy={"no-referrer"}
        title={widget.iframeShape?.src}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetIframe on WidgetIframe {
    id
    __typename
    iframeShape {
      src
      height
    }
  }
`;

export default memo(IframeWidget);
